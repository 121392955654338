<template>
    <div class="islamic-table" :style="{ background: currentTheme.primary, fontFamily: element.styles.fontFamily}">
        <div class="table-header" :style="{ background: currentTheme.primary, color: currentTheme.txt_secondary }">
            <div class="table-item fw-bold" :style="{width: `calc(100% / ${getRows.length})`}">
                {{ $t('widgets.prayer', this.language) }}

            </div>
            <!-- make the dates also dynamic with page -->
            <div class="table-item" :style="{width: `calc(100% / ${getRows.length})`}" v-for="(item) in getRows" :key="item">
                {{ item !== null ? $dayjs(item["Date"]).locale(this.language).format('dddd, D') : '' }}
            </div>
        </div>
        <div class="table-col" v-for="(prayer, index) in prayers" :key="prayer"
            :style="{ background: index % 2 !== 0 ? currentTheme.primary : currentTheme.secondary, color: currentTheme.txt_secondary }">
            <div class="table-item prayer-item" :style="{width: `calc(100% / ${getRows.length})`}">
                <span class="prayer" :style="{ color: currentTheme.txt_primary }">
                    {{ prayer }}
                </span>
            </div>
            <div class="table-item" :style="{width: `calc(100% / ${getRows.length})`}" v-for="(item) in getRows" :key="item">
                <!-- set active to date that is close to current date, if there's a 10:00 and 12:00, and current time is 10:20, it should set active to 10:00 -->
                <span 
                    class="time"
                    :style="spanStyle(item, prayer)" 
                    :class="{ active: isActive(item, prayer), smaller: element.height < 560 }">
                    {{ item !== null ? item["Times"][prayer] : '' }}
                </span>
            </div>
        </div>

        <div class="table-footer">
            <div class="table-page" :style="{ color: currentTheme.txt_secondary }">
                <!-- there's always 7 dates in tolal, for example if we have 5 dates, it should display 2 pages -->
                {{ this.currentPage }} / {{ Math.ceil(this.items.length / this.datesPerPage) }}  
            </div>
        </div>
    </div>

</template>

<script>
import { composerStore, useAppStore } from '@/store';
import { useOnline } from '@vueuse/core'

export default {
    props: {
        element: {},
        styles: {},
        preview: {},
        stb: {},
        timezone: {},
        tenant: {
            type: String,
            required: false
        },
        language: {
            type: String,
            default: 'EN',
            required: false
        },
    },
    setup() {
        const elementsStore = composerStore();
        const online = useOnline()
        const appStore = useAppStore();

        return { elementsStore, online, appStore }
    },
    data() {
        return {
            prayers: ['Fajr', 'Dhuhr', 'Asr', 'Maghrib', 'Isha'],
            datesPerPage: 1,
            currentPage: 1,
            closestPrayer: null,
            items: [],
            interval: null,
            newDayInterval: null,
            dateInterval: null,
            timeZ: this.timezone || this.appStore?.settings?.timezone || 'UTC',
         }
    },
    async mounted() {
        this.datesPerPage = this.checkWidth();
        await this.getCalendarItems();
    },
    beforeUnmount() {
        this.clearIntervals();  
    },
    computed: {
        isActive() {
            return (element, prayer) => {
                return this.closestPrayer === prayer && element?.Date && element['Date'] === this.$dayjs().tz(this.timeZ).format('YYYY-MM-DD');
            }
        },
        spanStyle() {
            return (element, prayer) => {
                return {
                    color: this.isActive(element, prayer) ? this.currentTheme.txt_primary : '',
                    borderColor: this.isActive(element, prayer) ? this.currentTheme.txt_primary : ''
                };
            }
        },
        currentTheme() {
            const theme = this.element.theme;
            return theme;
        },
        getRows() {
            const startIndex = (this.currentPage - 1) * this.datesPerPage;
            const endIndex = startIndex + this.datesPerPage;

            // Get the items for the current page
            const pageItems = this.items.slice(startIndex, endIndex);

            // Only fill up the last page if it's not full
            if (pageItems.length < this.datesPerPage) {
                const remainingSpaces = this.datesPerPage - pageItems.length;
                return [...pageItems, ...Array(remainingSpaces).fill(null)];
            }

            // Return the items for the page without padding if full
            return pageItems;
        },
    },
    watch: {
        "element.width"() {
            this.currentPage = 1;
            this.datesPerPage = this.checkWidth();           
        },
        online(status) {
            if(status) this.getCalendarItems();
        },
    },
    methods: {
        closestPrayerTime() {
            if (!this.items.length) return false;

            const currentTime = this.$dayjs().tz(this.timeZ);
            const prayerTimes = this.items[0]["Times"];
            let closestPrayer = null;
            let closestTime = null;
            this.closestPrayer = null;

            for (const [prayer, time] of Object.entries(prayerTimes)) {
                const prayerTime = this.$dayjs(`${this.items[0]["Date"]} ${time}`, 'YYYY-MM-DD HH:mm');
                
                // Check if the prayer time is after the current time and is closer than the current closest time
                if (currentTime.isBefore(prayerTime) && (!closestTime || prayerTime.isBefore(closestTime))) {
                    closestPrayer = prayer;
                    closestTime = prayerTime;  // Update closestTime here
                }
            }

            // If all remaining prayers for the day are past, set closestPrayer to the last prayer
            if (!closestPrayer && this.prayers.length) {
                closestPrayer = this.prayers[this.prayers.length - 1];
            }

            this.closestPrayer = closestPrayer;
        },
        async getCalendarItems() {
            this.items = await this.elementsStore.getIslamicCalendar(this.$dayjs().tz(this.timeZ).format('YYYY-MM-DD'), this.element.settings?.latitude, this.element.settings?.longitude, this.tenant);
            
            // Remove first entry if current date is after date
            const currentTime = this.$dayjs().tz(this.timeZ);
            const itemDate = this.$dayjs(this.items[0]["Date"]);

            // Compare only the formatted date strings
            if (currentTime.format('YYYY-MM-DD') > itemDate.format('YYYY-MM-DD')) {
                this.items.shift();
            }

            if (this.items.length === 7) {
                this.items = this.items.slice(0, 6);
            }

            this.clearIntervals();
            this.closestPrayerTime();
            if(!this.preview) return;
            this.runInterval();
        },
        clearIntervals(){
            clearInterval(this.interval);
            clearInterval(this.dateInterval);
            clearTimeout(this.newDayInterval);
        },
        runInterval(){
            this.clearIntervals();
            this.interval = setInterval(() => {
                if(this.currentPage >= Math.ceil(this.items.length / this.datesPerPage))
                    this.currentPage = 1;
                else
                    this.currentPage++;
            }, this.element?.settings?.transition * 1000);

            this.dateInterval = setInterval(() => {
                this.closestPrayerTime();
            }, 2000)

            let intervalTime = 1000 * 60 * 60 * 24;
            const currentDate = this.$dayjs(this.$dayjs().tz(this.timeZ).format('YYYY-MM-DD HH:mm:ss'));
            const nextHour = currentDate.add(1, 'day').startOf('day');
            const timeUntilNextHour = Math.abs(nextHour.diff(currentDate)); 
            intervalTime = timeUntilNextHour;
            this.newDayInterval = setTimeout(() => {
                this.getCalendarItems();
            }, intervalTime);            
        },
        checkWidth(){
            const containerWidth = this.element.width;
            const initialWidth = 340;
            const widthIncrement = 250;
            let countColumns = 1;

            if (containerWidth > initialWidth) {
                countColumns += Math.floor((containerWidth - initialWidth) / widthIncrement);
            }

            return Math.min(countColumns, 6);
        },
    }
}
</script>

<style lang="scss" scoped>
.islamic-table {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .table-header {
        width: 100%;
        min-height: 88px;
        max-height: 88px;
        display: flex;

        .table-item {
            height: 100%;
            font-size: 28px;
            font-weight: 600;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.503px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .table-col {
        width: 100%;
        height: 100%;
        display: flex;

        .table-item {
            height: 100%;
            font-size: 40px;
            font-weight: 600;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.503px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.prayer-item {
                justify-content: start;
                padding-left: 60px;
            }

            & .active {
                border-radius: 20.532px;
                padding: 10px 20px;
                border: 4px solid;

                &.smaller {
                    padding: 0px 15px;
                }
            }
        }
    }

    .table-footer {
        min-height: 51px;
        max-height: 51px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .table-page{
            font-size: 32.852px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.503px;
        }
    }
}
</style>