<template>
  <div class="container1 flights" :style="{ 'background-color': currentTheme.primary, fontFamily: element.styles.fontFamily }">
    <div class="titles1" :style="{ 'background-color': currentTheme.primary, color: currentTheme.txt_primary }">
      <div>
        {{ this.element.type === 3 && !this.preview ? `${$t('widgets.arrivals', this.language)} / ${$t('widgets.departures', this.language)}` : this.title }}
      </div>
      <div class='d-flex align-items-center justify-content-center gap-1'>
        <div class="airport-icon">
          <svg v-show="this.icon == 2" width="19" height="17" viewBox="0 0 19 17" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.6812  5.18571L11.058 7.31375L13.7065 6.32884C14.2365 6.13267 14.7007 6.04356 15.092 6.06514C15.4179 6.0831 15.6938 6.17478 15.9214 6.34227C16.0475 6.43575 16.0821 6.51211 16.0733 6.54181C16.0728 6.5438 16.0114 6.74165 15.2656 7.02049L6.76482 10.1821C6.29152 10.3572 5.55084 10.4854 4.87955 10.5088C4.70177 10.5151 4.54879 10.5127 4.41559 10.5053C4.03241 10.4842 3.82326 10.4184 3.74391 10.3657C3.77615 10.3333 3.83544 10.2822 3.93657 10.2113L4.17497 10.0474L3.38123 8.6151L3.93391 8.41013L5.78781 9.29311L8.96554 8.08784L6.86619 5.17499L7.6812 5.18571ZM7.81631 4.58947C7.81631 4.58947 7.79787 4.58845 7.78967 4.588L6.97466 4.57728C6.73061 4.57389 6.48622 4.70933 6.34697 4.92099C6.20772 5.13266 6.20061 5.3838 6.33035 5.562L7.98106 7.85447L5.90889 8.63975L4.28541 7.86606C4.22 7.83428 4.14733 7.81619 4.06947 7.8119C3.97725 7.80681 3.88259 7.82172 3.78957 7.85684L3.23689 8.06181C3.05129 8.13006 2.89662 8.27246 2.81396 8.44498C2.73129 8.61751 2.73244 8.80472 2.81477 8.95414L3.36542 9.9484C3.34953 9.96362 3.33408 9.97685 3.32023 9.99219C3.1883 10.1258 3.11223 10.2967 3.11379 10.4638C3.11534 10.6309 3.19197 10.778 3.32674 10.8699C3.51942 10.9993 3.83787 11.0772 4.27844 11.1015C4.43827 11.1103 4.61425 11.1119 4.80022 11.1061C5.55277 11.0812 6.36103 10.9386 6.90961 10.7334L15.4125 7.57191C15.6911 7.46854 16.5406 7.15316 16.6855 6.59569C16.7358 6.40126 16.7174 6.1165 16.3547 5.849C16.0434 5.62054 15.6657 5.49306 15.2292 5.469C14.7436 5.44223 14.1827 5.54613 13.5642 5.77566L11.2216 6.64663L8.08378 4.67263C8.00557 4.62405 7.91467 4.59489 7.81631 4.58947Z"
              :fill="currentTheme.txt_primary" :stroke="currentTheme.txt_primary" stroke-width="0.2" />
            <path d="M9.36328 11.7568H16.0825" :stroke="currentTheme.txt_primary" stroke-width="0.8"
              stroke-linecap="round" />
          </svg>
          <svg v-show="this.icon == 1" width="19" height="17" viewBox="0 0 19 17" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.83099 4.59423L11.2044 8.3419L13.9029 9.18014C14.4423 9.34858 14.8643 9.5616 15.1609 9.81778C15.4079 10.031 15.5704 10.2722 15.6482 10.5438C15.691 10.6949 15.6717 10.7765 15.6466 10.7946C15.645 10.7959 15.4755 10.915 14.7147 10.6802L6.05319 7.99006C5.57148 7.83957 4.90675 7.48858 4.36102 7.09699C4.21643 6.99337 4.09677 6.89802 3.99581 6.81083C3.70534 6.56002 3.57999 6.38011 3.54935 6.28993C3.59469 6.28395 3.67282 6.27975 3.79619 6.2854L4.08509 6.30124L4.33165 4.68242L4.89443 4.85778L5.82276 6.68939L9.07489 7.67647L9.19229 4.08785L9.83099 4.59423ZM10.3022 4.20472C10.3022 4.20472 10.2882 4.19265 10.282 4.18728L9.64331 3.6809C9.45217 3.52912 9.17594 3.48705 8.93639 3.56956C8.69684 3.65207 8.53778 3.84656 8.53164 4.0669L8.43803 6.89028L6.31776 6.24609L5.50509 4.64177C5.47272 4.57665 5.42624 4.51794 5.36721 4.46697C5.29731 4.40661 5.21326 4.36058 5.11816 4.33156L4.55538 4.1562C4.36676 4.09685 4.15731 4.1151 3.98647 4.20119C3.81562 4.28728 3.70217 4.4362 3.67606 4.60479L3.50462 5.72834C3.48274 5.73068 3.46242 5.73172 3.44209 5.7354C3.25603 5.76057 3.09142 5.84939 2.99056 5.98263C2.88971 6.11588 2.86051 6.27916 2.91104 6.43428C2.98457 6.6544 3.18908 6.91064 3.52304 7.19901C3.6442 7.30362 3.78251 7.41244 3.93332 7.52142C4.54434 7.96143 5.27135 8.34231 5.83102 8.51498L14.4941 11.2064C14.7778 11.2948 15.6431 11.5641 16.0984 11.2113C16.257 11.0881 16.4163 10.8514 16.2927 10.418C16.1858 10.047 15.9646 9.71529 15.6337 9.42961C15.2656 9.11173 14.758 8.85133 14.1282 8.65526L11.7415 7.91369L10.4632 4.43395C10.4309 4.34772 10.3768 4.2691 10.3022 4.20472Z"
              :fill="currentTheme.txt_primary" :stroke="currentTheme.txt_primary" stroke-width="0.2" />
            <path d="M3.36328 11.7568H10.0825" :stroke="currentTheme.txt_primary" stroke-width="0.8"
              stroke-linecap="round" />
          </svg>
        </div>
        <span class='me-4' v-if="this.element.settings.airport.name">
          {{ this.element.settings.airport.name.replace(/\s*\(.*?\)/, '').trim() }}
        </span>
      </div>
    </div>
    <div class="subtitles1" :style="{ 'background-color': currentTheme.secondary, 'color': currentTheme.txt_secondary }">
      <span :style="{
        'font-size': getFontSize('text') + 'px',
        'width': '20%',
        'align-items': 'center', 'justify-content': 'center',
        'text-align': 'center'
      }" v-for="(element, index) in subtitles" :key="index">
        {{ element }}
      </span>
    </div>
    <div class="body1" :style="{background: currentTheme.secondary }">
      <div v-for="(elementRow, index) in this.result" :key="index">
        <div class="row1" v-if="index < this.checkHeight()" :style="{
          'background-color': index % 2 === 0 ? currentTheme.tertiary : currentTheme.secondary,
          'color': currentTheme.txt_secondary,
          fontSize: getFontSize('text') + 'px' 
        }">
          <div class="item">
            <div v-if="elementRow.arrivaltime || elementRow.departuretime" style="display: flex; align-items: center; justify-content: space-around; flex-direction: column;">
              <span :style="{ 'font-size': getFontSize('text') + 'px' }">
                {{ this.translateDate(elementRow.arrivaltime ? elementRow.arrivaltime : elementRow.departuretime, true)
                }}</span>
              <span :style="{ 'font-size': getFontSize('text') + 'px'}">
                {{ this.translateDate(elementRow.arrivaltime ? elementRow.arrivaltime : elementRow.departuretime, false)
                }}</span>
            </div>
          </div>
          <div class="item">
            <span class="d-flex">
              <img
                v-if="imageSrc(elementRow.ident_icao.split(` `)[0].toLowerCase())" 
                class="rounded"
                width="160px" 
                :src="imageSrc(elementRow.ident_icao.split(` `)[0].toLowerCase())"
                alt=""
              />
              <span v-else>{{ elementRow.airline }}</span>
            </span>
          </div>
          <div class="item">
            <span>
              {{ elementRow.ident }}</span>
          </div>
          <div class="item">
            <span :class="{ smaller: this.departures ? elementRow.destinationCity.length > 10 : elementRow.originCity.length > 10 }">
              {{ this.departures ? elementRow.destinationCity : elementRow.originCity }} </span>
          </div>
          <div class="item">
            <span>
              {{ this.$t('widgets.' + elementRow.status.toLowerCase(), this.language) }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { composerStore, useAppStore } from "@/store";
import flightsData from "@/assets/dummy/flights"

const INTERVAL = 1 // IN HOURS

const FONT_SIZES = {
    Small: {
        text: 25,
    },
    Medium: {
        text: 29,
    },
    Large: {
        text: 32,
    }
}

export default {
  props: {
    language: {
      type: String,
      required: false,
      default: "EN"
    },
    element: {},
    index: {},
    preview: {},
    stb: {}
  },
  setup() {
    const elementsStore = composerStore();
    const appStore = useAppStore();
    return { elementsStore, appStore };
  },
  data() {
    return {
      fontSizes: FONT_SIZES,
      subtitles: [] ,
      flights: [],
      timezone: "",
      cities: this.appStore.cities || [],
      data: [],
      result: [],
      departures: false,
      timer: undefined,
      refresh: undefined,
      time: 5000,
      currentPage: 1,
      rowsPerPage: 3,
      indexInitial: 0,
      indexFinal: 7,
      title: 'Arrivals',
      icon: 1
    };
  },
  watch: {
    "element.type"() {
      this.getData();
    },
    "element.height"() {
      this.rowsPerPage = this.checkHeight();
      this.setType();
    }
  },
  async mounted() {
    // eslint-disable-next-line vue/no-mutating-props
    if(this.element?.settings?.fontSize === 'medium') this.element.settings.fontSize = 'Medium'; // Fix for previous versions
    if(this.stb) this.cities = await this.appStore.getCities();
    await this.getData();

    if (this.preview) { 
      if(!Object.keys(this.flights).length) return;
      this.timer = setInterval(this.element.type === 3 ? this.updateDataMultiple : this.updateData, this.element.settings.time * 1000);
      this.refresh = setInterval(this.getData, INTERVAL * 60 * 60 * 1000);
    }
  },
  computed: {
    currentTheme() {
      const theme = this.element.theme;
      return theme;
    },
    imageSrc() {
      return (ident_icao) => {
        try {
          return require('@/assets/airlines/' + ident_icao + '.png')
        } catch (e) {
          return null;
        }
      }
    },
  },
  methods: {
    adjustCounter() {
      let counter = 1;
      const maxCounter = this.element?.settings?.fontSize == 'Small' ? 4 : this.element?.settings?.fontSize == 'Medium' ? 8 : 11;

      if (this.element.width < 1600 && this.element.width >= 740) {
        const normalizedWidth = (1600 - this.element.width) / (1600 - 740);
        counter = Math.min(maxCounter, Math.max(1, normalizedWidth * maxCounter));
      } else {
        counter = 0; // Reset counter if the width exceeds the range
      }

      return counter
    },
    getFontSize(field, scaleWidth = true) {
      const counter = scaleWidth ? this.adjustCounter() : 0;
      return this.fontSizes[this.element?.settings?.fontSize][field] - counter;
    },
    setType() {
      if (this.element.type === 3) {
        this.setArrayCombined();
      } else {
        this.setArray();
      }
    },

    setArray() {
      const maxRows = !this.preview ? 20 : this.checkHeight();
      const newArray = this.element.type === 1 ? this.flights.arrivals : this.flights.departures;
      this.currentPage = 1; // Initialize current page
      this.rowsPerPage = maxRows; // Set the number of rows per page
      this.data = newArray; // Initial data for the first page
      this.updateData();
    },
    setArrayCombined() {
      const maxRows = !this.preview ? 20 : this.checkHeight();

      // Create an array of data arrays with arrivals first and then departures
      const dataQueue = {
        arrivals: this.flights.arrivals,
        departures: this.flights.departures
      };

      this.currentPage = 1; // Initialize current page
      this.rowsPerPage = maxRows; // Set the number of rows per page
      this.data = dataQueue; // Initial data for the first page
      this.updateDataMultiple();
    },
    updateDataMultiple() {
      // Flip departures first to ensure correct dataset selection
      if (this.currentPage > Math.ceil((this.departures ? this.data.departures : this.data.arrivals).length / this.rowsPerPage)) {
        this.currentPage = 1;
        this.departures = !this.departures;  // 🔄 Switch before selecting the data
        this.icon = this.departures ? 2 : 1;
      }

      this.title = this.departures ? this.$t('widgets.departures', this.language) : this.$t('widgets.arrivals', this.language);
      this.subtitles = this.departures 
        ? [this.$t('widgets.hour', this.language), this.$t('widgets.company', this.language), this.$t('widgets.flight', this.language), this.$t('widgets.destination', this.language), this.$t('widgets.status', this.language)] 
        : [this.$t('widgets.hour', this.language), this.$t('widgets.company', this.language), this.$t('widgets.flight', this.language), this.$t('widgets.origin', this.language), this.$t('widgets.status', this.language)];

      const rows = this.departures ? this.data.departures : this.data.arrivals;
      // const totalPages = Math.ceil(rows.length / this.rowsPerPage);

      // Calculate the start and end indices
      const startIndex = (this.currentPage - 1) * this.rowsPerPage;
      const endIndex = startIndex + this.rowsPerPage;

      this.result = rows.slice(startIndex, endIndex);
      this.currentPage++;
    },
    updateData() {
      // Calculate the start and end indices for the current page
      const startIndex = (this.currentPage - 1) * this.rowsPerPage;
      const endIndex = startIndex + this.rowsPerPage;

      // Slice the data to get the current page's data
      this.result = this.data.slice(startIndex, endIndex);

      // Increment currentPage for the next iteration
      this.currentPage++;
      // If the current page exceeds the total number of pages, reset it to 1
      if (this.currentPage > Math.ceil(this.data.length / this.rowsPerPage)) {
        this.currentPage = 1;
      }

      // fill the empty rows (keeping in case it's needed)
      // if (this.result.length < this.rowsPerPage) {
      //   const emptyRows = this.rowsPerPage - this.result.length;
      //   for (let i = 0; i < emptyRows; i++) {
      //     this.result.push({});
      //   }
      // }
    },
    async getData() {
      this.data = [];
      this.subtitles = this.element.type === 2 ? 
        [this.$t('widgets.hour', this.language), this.$t('widgets.company', this.language), this.$t('widgets.flight', this.language), this.$t('widgets.destination', this.language), this.$t('widgets.status', this.language)] :
        [this.$t('widgets.hour', this.language), this.$t('widgets.company', this.language), this.$t('widgets.flight', this.language), this.$t('widgets.origin', this.language), this.$t('widgets.status', this.language)] ;

      await this.dataAPI();
      this.timezone = '';

      if(this.cities.find(location => location.city === this.flights.cityNameAirport)?.timezone){
        this.timezone = this.cities.find(location => location.city === this.flights.cityNameAirport).timezone
      }

      if (this.element.type === 1 || this.element.type === 2) {
        this.title = this.element.type === 1 ? this.$t('widgets.arrivals', this.language) : this.$t('widgets.departures', this.language);
        this.icon = this.element.type;
        this.departures = this.element.type === 1 ? false : true;
      }
      
      if(!Object.keys(this.flights).length) return;
      this.setType();
    },
    checkHeight() {
      const containerHeight = this.element.height;
      const initialHeight = 458;
      const heightIncrement = 88;
      let countRows = 3;

      if (containerHeight > initialHeight) {
        countRows += Math.floor((containerHeight - initialHeight) / heightIncrement);
      }

      return countRows;
    },
    translateDate(date, hour) {
      let data;
      if(!this.timezone)
        data = dayjs.unix(date);
      else
        data = dayjs.unix(date).tz(this.timezone);

      if (!hour) return data.format('DD-MM-YY')
      return data.format('HH:mm')
    },
    async dataAPI() {
      if(!this.preview) return this.flights = JSON.parse(JSON.stringify(flightsData));
      
      try {
        const airport = this.element.settings.airport.value;
        
        await axios.get(`airport_flights/${airport}`).then(response => {
        
        let result = { arrivals: [], departures: [] };

        if(!response.data["arrivals"] || !response.data["departures"] || !response.data["arrivals"].length || !response.data["departures"].length) 
          return result;

        this.result = response.data;

        if(!Array.isArray(this.result["arrivals"]))
          this.result["arrivals"] = [];

        if(!Array.isArray(this.result["departures"])){
          this.result["departures"] = [];
        }

        return this.flights = this.result;
        
        })
      } catch (error) {
        console.log(error)
        this.flights = { arrivals: [], departures: [] };
      }
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
    clearInterval(this.refresh);
  },
};
</script>

<style lang="scss" scoped>
.container1 {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-weight: 600;
}

.airport-icon {
  display: flex;
  height: 33.7px;
  width: 40px;

  & svg {
    width: 100%;
    height: 100%;
  }
}

svg.special {
  scale: 0.8;
}

.titles1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 61px;
  width: 100%;
  height: 115.4px;
  line-height: 0.9;
  font-weight: normal;
  white-space: nowrap;

  & span {
    font-size: 32px;
  }
}

.subtitles1 {
  flex-direction: row;
  display: flex;
  width: 100%;
  min-height: 88.6px;
  height: 88.6px;
  align-items: center;
  justify-content: space-around;
  color: white;
  font-size: 25px;
}

.body1 {
  height: calc(100% - 138px);
  width: 100%;
  align-items: center;
}

.row1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 88px;
}

.item {
  display: flex;
  width: 20%;
  height: 88px;
  line-height: 1.05;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-word;
}
</style>
